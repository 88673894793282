import { animated, useSpring, useTransition } from '@react-spring/web';
import { globalLogo, menuItems, REFERRAL_CODE_KEY } from './constants';
import {
  startCommunityForFreeButtonClick,
  trackGAEvent
} from '@/utility/analytics';
import { useEffect, useState } from 'react';

import ChevronDown from '@/components/common/IconsV2/ChevronDown';
import CloseIcon from '@/components/common/Icons/CloseIcon';
import { Fragment } from 'react';
import HamburgerIcon from '@/components/common/Icons/HamburgerIcon';
import {
  NAVBAR_ITEM_CLICK_EVENT,
  VISIT_REFERRAL_LINK
} from '@/utility/analyticsConsts';
import NasIoLogo from '@/components/common/IconsV2/NasIoLogo';
import NextImage from '@/components/common/NextImage';
import {
  routeToStartCommunityForm,
  routeToStartWhatsappCommunity
} from '@/utility/urlHelpers';
import { useAdminAuthContext } from '@/contexts/AdminAuthContext';
import { useAuthContext } from '@/contexts/AuthContext';
import { useRouter } from 'next/router';
import { useUserSessionContext } from '@/contexts/UserSessionContext';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import WhatsappAlert from '@/pages/home/components/WhatsappAlert/WhatsappAlert';
import NPLAlert from '@/components/common/NPLAlert';
import { ALERT_TYPE } from '@/components/common/NPLAlert/constants';
import Icon from '@/icons/Icon';
import classNames from 'classnames';
import RoundedButton from '@/components/common/RoundedButton';
import { CM_PORTAL_HOMEPAGE_ROUTE } from '@/utility/routesHelper';

const isWhatsappHomePage = (router) => {
  return router.pathname === '/whatsapp';
};

const NavbarV2 = ({
  isHomeRoute,
  isWhatsappOnboardingEnabled = false
}) => {
  const { isGtEqMd, isGtEqLg } = useWindowWidthContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuDropDownShowing, setMenuDropDownShowing] = useState('');
  const [menuAccordianShowing, setMenuAccordianShowing] = useState('');
  const { sessionHomePageLogoLink } = useUserSessionContext();
  const { isUserLoggedIn: isAdminLoggedIn, isDemoUser } =
    useAdminAuthContext();
  const { isLoggedIn: isUserLoggedIn } = useAuthContext();
  const router = useRouter();
  const { query } = router;
  const [referralCode, setReferralCode] = useState();

  // Logic for hiding navbar
  let oldScrollY = 0;

  const [showNavbar, setShowNavbar] = useState(true);

  const controlDirection = () => {
    setMenuDropDownShowing('');
    if (window.scrollY > oldScrollY && window.scrollY > 300) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', controlDirection);
    return () => {
      window.removeEventListener('scroll', controlDirection);
    };
  }, []);

  useEffect(() => {
    if (query?.referral) {
      const savedReferralCode = localStorage.getItem(REFERRAL_CODE_KEY);
      if (savedReferralCode !== query?.referral) {
        trackGAEvent(VISIT_REFERRAL_LINK, {
          referralCode: query?.referral
        });
      }
    }

    if (!isUserLoggedIn) {
      if (query?.referral) {
        localStorage.setItem(REFERRAL_CODE_KEY, query?.referral);
        setReferralCode(query?.referral);
      } else {
        setReferralCode(localStorage?.getItem(REFERRAL_CODE_KEY));
      }
    } else {
      localStorage.removeItem(REFERRAL_CODE_KEY);
    }
  }, [query?.referral]);

  // Animations
  const transitionNavbar = useTransition(showNavbar, {
    from: { y: -200 },
    enter: { y: 0 },
    leave: { y: -200 },
    reverse: showNavbar
  });

  const styles = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 300 },
    reset: true
  });

  const stylesMobile = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 300 }
  });

  const handleHover = (item) => {
    if (item.items) {
      setMenuDropDownShowing(
        menuDropDownShowing === `${item?.id}-dropdown`
          ? ''
          : `${item?.id}-dropdown`
      );
    }
  };

  const handleClick = (item) => {
    if (!item.items) {
      trackGAEvent(NAVBAR_ITEM_CLICK_EVENT, { link: item.link });
      router.push(item.link);
    }
  };

  const handleMobileAcorrdianClick = (item) => {
    if (item.items) {
      setMenuAccordianShowing(
        menuAccordianShowing === `${item?.id}-row` ? '' : `${item?.id}-row`
      );
    } else {
      router.push(item.link);
    }
  };

  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsMenuOpen((prev) => !prev);
  };

  const handleStartCommunityClick = () => {
    // analytics tracking
    startCommunityForFreeButtonClick({
      page: 'navbar',
      section: 'navbar',
      time: new Date().getTime()
    });

    if (isWhatsappHomePage(router) && isWhatsappOnboardingEnabled) {
      routeToStartWhatsappCommunity(router);
      return;
    }

    trackGAEvent(NAVBAR_ITEM_CLICK_EVENT, {
      link: '/start-community'
    });
    routeToStartCommunityForm(router);
  };

  const directToHome = (e) => {
    e.stopPropagation();
    trackGAEvent(NAVBAR_ITEM_CLICK_EVENT, {
      link: sessionHomePageLogoLink
    });
    router.push({
      pathname: sessionHomePageLogoLink,
      query: { noRedirect: true }
    });
  };

  const renderDesktopActionButtons = () => {
    // const showLoginButton = !isUserLoggedIn && !isAdminLoggedIn
    const showLoginButton =
      (!isAdminLoggedIn && !isUserLoggedIn) || isDemoUser;

    const showGoToCmPortalBtn = isAdminLoggedIn && !isDemoUser;
    const showStartCommunityCta = !isAdminLoggedIn || isDemoUser;

    return (
      <div className="flex flex-row items-center gap-16">
        {showLoginButton && (
          <a
            href="/login"
            onClick={() => {
              trackGAEvent(NAVBAR_ITEM_CLICK_EVENT, { link: '/login' });
            }}>
            <div className="px-16 border-b py-28 border-dark-33 xl:border-b-0 md:px-0 md:py-0">
              <p
                className={` text-16 cursor-pointer hover:bg-light-aa-opaque w-full text-center py-12 px-16 text-neutral-99
        rounded-full border-light-e0 border-solid border-1 whitespace-nowrap font-semibold bg-transparent md:w-initial`}>
                {'Log in'}
              </p>
            </div>
          </a>
        )}
        {showGoToCmPortalBtn && (
          <a
            href="/portal"
            onClick={() => {
              trackGAEvent(NAVBAR_ITEM_CLICK_EVENT, {
                link: CM_PORTAL_HOMEPAGE_ROUTE
              });
            }}>
            <div className="px-16 border-b py-28 border-dark-33 xl:border-b-0 md:px-0 md:py-0">
              <p
                className={`text-16 cursor-pointer text-neutral-10 hover:bg-yellow-80 w-full bg-primary text-center py-12
                border-1 px-16 border-primary rounded-full whitespace-nowrap font-semibold md:w-initial border-solid`}>
                {'Go To Portal'}
              </p>
            </div>
          </a>
        )}
        {showStartCommunityCta && (
          <RoundedButton
            displayType="primary"
            onClick={handleStartCommunityClick}
            customClassNames="!font-semibold !text-button-lg h-[50px]">
            Start for free
          </RoundedButton>
        )}
      </div>
    );
  };

  const renderMobileActionButtons = () => {
    return (
      <div className="flex flex-col items-center w-full gap-8">
        <a
          className="w-full"
          href="/login"
          onClick={() => {
            trackGAEvent(NAVBAR_ITEM_CLICK_EVENT, { link: '/login' });
          }}>
          <div className="w-full px-16 pb-8 pt-14 md:px-0 md:py-0">
            <p
              className={` text-16 cursor-pointer hover:bg-light-aa-opaque w-full text-center py-12 px-16 text-neutral-99
        rounded-full border-light-e0 border-solid border-1 whitespace-nowrap font-semibold bg-transparent md:w-initial`}>
              {'Log in'}
            </p>
          </div>
        </a>
        {/* <a className="w-full" href={`/start-community`}>
          <div className="w-full px-16 pt-8 pb-14 md:px-0 md:py-0">
            <p
              className={`text-16 cursor-pointer text-neutral-10 hover:bg-yellow-80 w-full bg-primary text-center py-12
        border-1 px-16 border-primary rounded-full font-semibold md:w-initial border-solid`}>
              Request Free Access
            </p>
          </div>
        </a> */}
        <button className="w-full" onClick={handleStartCommunityClick}>
          <div className="w-full px-16 pt-8 pb-14 md:px-0 md:py-0">
            <p
              className={`text-16 cursor-pointer text-neutral-10 hover:bg-yellow-80 w-full bg-primary text-center py-12
        border-1 px-16 border-primary rounded-full font-semibold whitespace-nowrap md:w-initial border-solid`}>
              Start for free
            </p>
          </div>
        </button>
      </div>
    );
  };

  const renderDesktopMenuItems = () => {
    return (
      <div className="flex flex-row items-center ml-16">
        {menuItems.map((item, index) => {
          return (
            <div key={index} className="relative inline-block text-left">
              <div>
                <button
                  type="button"
                  className={`inline-flex justify-center p-12 text-[#FBFCFF] text-16 font-medium ${
                    menuDropDownShowing === `${item?.id}-dropdown`
                      ? 'bg-[#454749]'
                      : ''
                  }
                  hover:bg-[#454749] rounded-[40px] border-[#454749] transition-[background-color] `}
                  id={item?.id}
                  onMouseEnter={() => handleHover(item)}
                  onClick={() => handleClick(item)}>
                  {item?.label}
                  {item?.items && (
                    <div
                      className={`transition-transform ${
                        menuDropDownShowing === `${item?.id}-dropdown`
                          ? 'rotate-180'
                          : ''
                      }`}>
                      <ChevronDown
                        innerCustomClassNames="fill-light-f6"
                        customClassNames="ml-4"
                      />
                    </div>
                  )}
                </button>
              </div>
              {item?.items && renderMenuDropdown(item)}
            </div>
          );
        })}{' '}
      </div>
    );
  };

  const renderMenuDropdown = (item) => {
    return (
      <animated.div
        style={styles}
        className={`origin-top-left absolute left-0 mt-8 w-[360px] rounded-[16px] shadow-lg bg-[#191C1E] ${
          menuDropDownShowing === `${item?.id}-dropdown`
            ? 'flex'
            : 'hidden'
        }`}
        onMouseLeave={() => setMenuDropDownShowing('')}
        id={`${item?.id}-dropdown`}>
        <div className="flex flex-col w-full px-8 py-8" role="none">
          {item?.items.map((linkItem, index) => {
            return (
              <Fragment key={index}>
                <a
                  href={linkItem?.link}
                  className={`text-[#FBFCFF] text-16 font-medium w-full text-left px-12 py-12 hover:bg-[#2E3133] cursor-pointer hover:rounded-[16px]`}
                  role="menuitem"
                  tabIndex="-1"
                  target={linkItem?.isExternal ? '_blank' : ''}
                  rel="noopener noreferrer"
                  id={`menu-item-${item.id}-${index}`}
                  onClick={() => {
                    if (linkItem?.link) {
                      trackGAEvent(NAVBAR_ITEM_CLICK_EVENT, {
                        link: linkItem.link
                      });
                    }
                  }}>
                  {linkItem?.label}
                  {linkItem?.subtitle && (
                    <p className="text-14 text-light-aa">
                      {linkItem?.subtitle}
                    </p>
                  )}
                </a>
                {linkItem?.subtitle && (
                  <div className="w-full border-b-1 border-[#454749] my-6"></div>
                )}
              </Fragment>
            );
          })}
        </div>
      </animated.div>
    );
  };

  const renderMobileAccordian = () => {
    return (
      <div className="flex flex-col w-full">
        {menuItems.map((item, index) => {
          let selected = menuAccordianShowing === `${item?.id}-row`;
          return (
            <div key={index} className="flex flex-col">
              <div
                tabIndex={0}
                role="button"
                onClick={() => handleMobileAcorrdianClick(item)}
                className={`flex flex-row items-center justify-between cursor-pointer text-18 text-[#FBFCFF] font-medium py-28 ${
                  !selected && 'border-b border-[#5C5E61]'
                }`}>
                <span>{item.label}</span>
                {item.items && (
                  <div
                    className={`transition-transform ${
                      selected ? 'rotate-180' : ''
                    }`}>
                    <ChevronDown
                      innerCustomClassNames="fill-light-f6"
                      customClassNames="ml-4"
                    />
                  </div>
                )}
              </div>
              <div
                className={`flex flex-col overflow-hidden w-full transition-[max-height] duration-300 ease-in-out ${
                  selected ? 'max-h-[1000px]' : 'max-h-0'
                }`}>
                {item?.items?.map((linkItem, index) => {
                  return (
                    <a
                      key={index}
                      href={linkItem.link}
                      onClick={() => {
                        if (linkItem.link)
                          trackGAEvent(NAVBAR_ITEM_CLICK_EVENT, {
                            link: linkItem.link
                          });
                      }}
                      target={
                        isGtEqLg && linkItem?.isExternal ? '_blank' : ''
                      }
                      rel="noopener noreferrer"
                      className="py-16 text-16 text-[#FBFCFF] font-medium w-full cursor-pointer">
                      {linkItem.label}
                    </a>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderMobileMenu = () => {
    return (
      <animated.div
        style={stylesMobile}
        className="w-full sticky top-0 z-[100] min-h-screen max-h-screen overflow-y-scroll bg-neutral-10 flex flex-col justify-between p-24 pt-6">
        <div>
          <div className="w-full h-[100px] px-20 flex flex-row justify-between items-center">
            <NasIoLogo customClassNames={'h-32 w-32'} />
            <div
              tabIndex={0}
              role="button"
              onClick={(e) => {
                toggleMenu(e);
              }}>
              <CloseIcon
                customClassName={
                  'bg-[#5C5E61] rounded-full border-[#5C5E61] h-40 w-40 p-10'
                }
                fill={'#fff'}
              />
            </div>
          </div>
          <div className="w-full mt-32">{renderMobileAccordian()}</div>
        </div>
        <div>{renderMobileActionButtons()}</div>
      </animated.div>
    );
  };

  const renderMobileMenuButton = () => {
    return isMenuOpen ? (
      <button
        className="hover:bg-[#454749] hover:rounded-full"
        onClick={toggleMenu}>
        <CloseIcon fill={'#fff'} />
      </button>
    ) : (
      <button
        className="hover:bg-[#454749] p-6 hover:rounded-full"
        onClick={toggleMenu}>
        <HamburgerIcon />
      </button>
    );
  };

  const goToWhatsappPage = () => (window.location = '/whatsapp');

  const renderNavHeader = () => {
    return transitionNavbar(
      (styles, item) =>
        item && (
          <animated.div
            style={styles}
            onMouseLeave={() => setMenuDropDownShowing('')}
            className={`font-poppins sticky w-full mx-auto ${
              isHomeRoute ? 'md:mb-[140px]' : 'top-16'
            } md:top-40 h-[80px] z-50`}>
            <>
              {isHomeRoute && referralCode && !isUserLoggedIn && (
                <div className="mb-40">
                  <NPLAlert
                    type={ALERT_TYPE.INFORMATION}
                    iconAlertType={() => <Icon name="star-07" />}
                    customClassNames="border-none rounded-none"
                    title={`You are invited to start community via ${referralCode.toUpperCase()}`}
                    message="Earn USD 10 when you make your first USD 100"
                    isSingleLine
                    btnProps={[
                      {
                        ctaText: 'Start for free',
                        props: {
                          link: `/start-community?referral=${referralCode.toUpperCase()}`,
                          displayType: 'bg-transparent-w-border',
                          customClassNames:
                            'w-fit !py-6 !px-16 size-sm !border-npl-neutral-light-7',
                          fluid: false,
                          rounded: true,
                          slim: true,
                          narrow: true,
                          small: true
                        }
                      }
                    ]}
                  />
                </div>
              )}
              {isHomeRoute && !referralCode && (
                <WhatsappAlert
                  title="Try Nas.io for your WhatsApp group today!"
                  ctaText="Learn more"
                  onClick={goToWhatsappPage}
                />
              )}
              <div className="px-24 h-80 xl:max-w-[1120px] mx-auto xl:px-0 md:px-40">
                <div className="bg-[#313131] rounded-full px-20 flex flex-row justify-between items-center w-full h-full z-50">
                  <div className="inline-flex cursor-pointer">
                    {isGtEqLg ? (
                      <>
                        <button
                          className="flex items-center"
                          onClick={directToHome}>
                          <NextImage {...globalLogo.fullLogo} />
                        </button>
                        {renderDesktopMenuItems()}
                      </>
                    ) : (
                      <>
                        <button
                          className="flex items-center"
                          onClick={directToHome}>
                          <NasIoLogo customClassNames="h-32 w-32" />
                        </button>
                      </>
                    )}
                  </div>
                  <div className="flex items-center gap-16">
                    {isGtEqMd && renderDesktopActionButtons()}
                    {!isGtEqLg && renderMobileMenuButton()}
                  </div>
                </div>
              </div>
            </>
          </animated.div>
        )
    );
  };
  return (
    <div
      className={classNames(
        'sticky top-0',
        isHomeRoute
          ? 'h-[260px] z-[100] md:h-[220px]'
          : 'h-[120px] z-[100]',
        showNavbar ? 'pointer-events-auto' : 'pointer-events-none'
      )}>
      {isMenuOpen && !isGtEqLg ? renderMobileMenu() : renderNavHeader()}
    </div>
  );
};

export default NavbarV2;
