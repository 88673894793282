import { ALERT_TYPE } from './constants';
import Button from '../Button';
import CheckCircle from '../IconsV2/CheckCircle';
import IconByName from '../IconsV2/IconByName';
import PropTypes from 'prop-types';
import Icon from '@/icons/index';
import classNames from 'classnames';

const NPLAlert = ({
  type,
  iconAlertType,
  messageCustomClassNames,
  customClassNames,
  title,
  message,
  isSingleLine,
  onDismiss,
  btnProps
}) => {
  const getIconForNPLv2 = (type) => {
    switch (type) {
      case ALERT_TYPE.WARNING:
        return (
          <IconByName
            customClassName="fill-[#CE2C31]"
            name="error-alert-warning"
          />
        );
      case ALERT_TYPE.INFORMATION:
        return <Icon name="info" fill="#3442B2" fillOpacity="1" />;
      case ALERT_TYPE.SUCCESS:
        return <CheckCircle fill={'#18774C'} />;
      case ALERT_TYPE.ERROR:
        return (
          <IconByName
            customClassName="fill-[#CE2C31]"
            name="error-alert-warning"
          />
        );
      default: {
        if (typeof type === 'string' || !type) {
          return <></>;
        }
        const IconComponent = type;
        return <IconComponent></IconComponent>;
      }
    }
  };

  const getClassNames = (type) => {
    switch (type) {
      case ALERT_TYPE.WARNING:
        return 'bg-npl-yellow-light-3 border-1 border-npl-yellow-light-6 rounded-12';
      case ALERT_TYPE.ERROR:
        return 'bg-npl-error-light-3 border-1 border-npl-error-light-6 rounded-12';
      case ALERT_TYPE.SUCCESS:
        return 'bg-npl-success-light-3 border-1 border-npl-success-light-6 rounded-12';
      case ALERT_TYPE.NEUTRAL:
        return 'bg-npl-neutral-dark-3 text-white rounded-12';
      case ALERT_TYPE.NEUTRAL_LIGHT:
        return 'bg-npl-neutral-light-3 border-1 border-npl-neutral-light-6 rounded-12';
      case ALERT_TYPE.INFORMATION:
        return 'bg-npl-blurple-light-3 border-1 border-npl-blurple-light-6 rounded-12';
    }
  };
  return (
    <div
      className={classNames(
        `px-16 py-12 flex flex-row ${getClassNames(
          type
        )} ${customClassNames}`,
        {
          'lg:items-center lg:justify-center': isSingleLine
        }
      )}>
      <div>{getIconForNPLv2(iconAlertType ?? type)}</div>
      <div
        className={classNames('flex flex-col ml-14 items-start w-full', {
          'lg:flex-row lg:gap-8 lg:items-center w-auto': isSingleLine
        })}>
        <div
          className={classNames('flex items-center', {
            'w-full': !isSingleLine
          })}>
          <h1 className="font-medium text-npl-text-icon-on-light-surface-primary text-body-sm">
            {typeof title === 'string' || !title ? title : title()}
          </h1>
          {onDismiss && (
            <button onClick={onDismiss} className="ml-auto">
              <Icon
                name="close"
                width="16"
                height="16"
                customClassName="cursor-pointer min-w-[16px] min-h-[16px]"></Icon>
            </button>
          )}
        </div>
        <span
          className={classNames(
            'mt-4 font-normal text-npl-text-icon-on-light-surface-secondary text-body-sm ',
            {
              'lg:m-0': isSingleLine
            },
            messageCustomClassNames
          )}>
          {message}
        </span>
        {btnProps && Boolean(btnProps.length) && (
          <div
            className={classNames('mt-16 ml-0', {
              'lg:ml-24 lg:mt-0': isSingleLine
            })}>
            {btnProps.map((btnProp, index) => {
              return (
                <Button key={index} {...btnProp?.props}>
                  {btnProp?.ctaText}
                </Button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

NPLAlert.defaultProps = {
  type: ALERT_TYPE.NEUTRAL
};

NPLAlert.propTypes = {
  type: PropTypes.oneOf(Object.values(ALERT_TYPE)),
  iconAlertType: PropTypes.oneOf(Object.values(ALERT_TYPE)),
  customClassNames: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  isSingleLine: PropTypes.bool,
  btnProps: PropTypes.arrayOf(
    PropTypes.shape({
      ctaText: PropTypes.string,
      props: PropTypes.object
    })
  )
};

export default NPLAlert;
