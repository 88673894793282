import { domainRegions } from '@/utility/domainRegion';

export const REFERRAL_CODE_KEY = 'referralCode';

export const globalLogo = {
  fullLogo: {
    alt: ' Logo',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/svg/navbar_logo.svg',
      width: 110,
      height: 40
    }
  },
  shortLogo: {
    alt: 'NasIO Logo',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/svg/navbar_logo.svg',
      width: 91,
      height: 40
    }
  }
};

const phLogo = {
  fullLogo: {
    alt: 'NasIO PH Logo',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/svg/navbar_logo.svg',
      width: 110,
      height: 40
    }
  },
  shortLogo: {
    alt: 'NasIO PH Logo',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/svg/navbar_logo.svg',
      width: 91,
      height: 24
    }
  }
};

export const menuItems = [
  {
    label: 'Features',
    id: 'features',
    items: [
      {
        label: 'WhatsApp Communities',
        subtitle:
          'Supercharge your WhatsApp group. See the magic in 2 clicks',
        link: '/whatsapp'
      },
      {
        label: 'Event hosting',
        link: '/features/live-events'
      },
      {
        label: 'Paid membership',
        link: '/features/monetization'
      },
      {
        label: 'Newsletters',
        link: '/features/magic-reach'
      },
      {
        label: 'Email Marketing',
        link: '/features/magic-reach'
      },
      {
        label: 'Content Library',
        link: '/features/content-library'
      },
      {
        label: 'Chat Integration',
        link: '/features/chat-integrations'
      },
      {
        label: 'Website building',
        link: '/features/public-page'
      },
      {
        label: 'Analytics',
        link: '/features/analytics'
      },
      {
        label: 'Token gating',
        link: '/features/web3-tools'
      }
    ]
  },
  {
    label: 'For Business',
    link: '/business'
  },
  {
    label: 'Pricing',
    link: '/pricing'
  },
  {
    label: 'Company',
    id: 'company',
    items: [
      {
        label: 'About Us',
        link: '/about-us'
      },
      {
        label: 'Blog',
        link: '/blog',
        isExternal: true
      },
      {
        label: 'Careers',
        link: '/jobs',
        isExternal: true
      },
      {
        label: 'Academy',
        link: 'https://nasacademy.com/',
        isExternal: true
      },
      {
        label: 'Community Fund',
        link: '/grant'
      }
    ]
  }
];

export const getRegionSpecificLogoData = ({ region }) => {
  switch (region) {
    case domainRegions.PHILIPPINES:
      return phLogo;
    default:
      return globalLogo;
  }
};
