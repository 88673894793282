import React from 'react';
import RoundedButton from '@/components/common/RoundedButton';
import WhatsappColored from '@/components/common/IconsV2/WhatsappColored';

const WhatsappAlert = ({ title, ctaText, onClick }) => {
  return (
    <div className="bg-npl-succes-light-3 py-16 flex flex-col md:flex-row mb-40 md:justify-center md:items-center md:flex">
      <div className="flex pl-16 md:items-center md:pl-0">
        <div>
          <WhatsappColored width={24} height={24} />
        </div>
        <p className="ml-8 text-body-sm text-npl-text-icon-on-light-surface-primary md:text-label-lg md:font-medium md:mr-24">
          {title}
        </p>
      </div>
      <div className="ml-48 mt-16 md:ml-0 md:mt-0">
        <RoundedButton onClick={onClick} displayType="outline" size="sm">
          {ctaText}
        </RoundedButton>
      </div>
    </div>
  );
};

export default WhatsappAlert;
